import React, { useState, useEffect, useRef } from "react";

const GamePieces = ({ score, setScore, onGameOver }) => {
    const canvasRef = useRef();
    const SNAKE_SPEED = 15; // Speed to move the snake
    const BASE_SNAKE_WIDTH = 20; // Base width of the snake
    const BASE_SNAKE_HEIGHT = 35; // Base height of the snake
    const [apple, setApple] = useState({ x: 180, y: 100 });
    const [snake, setSnake] = useState([
        { x: 100, y: 50 },
        { x: 85, y: 50 },
    ]);
    const [direction, setDirection] = useState(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const getSnakeDimensions = (direction) => {
            if (direction === "up" || direction === "down") {
                return {
                    width: BASE_SNAKE_WIDTH * 1.5,
                    height: BASE_SNAKE_HEIGHT, // Twice as thick vertically
                };
            }
            return {
                width: BASE_SNAKE_WIDTH,
                height: BASE_SNAKE_HEIGHT,
            };
        };

        const drawSnake = () => {
            const { width, height } = getSnakeDimensions(direction);
            snake.forEach((snakePart) => {
                ctx.beginPath();
                ctx.rect(snakePart.x, snakePart.y, width, height); // Use dynamic dimensions
                ctx.fillStyle = "#90EE90";
                ctx.fill();
                ctx.closePath();
            });
        };

        const drawApple = () => {
            ctx.beginPath();
            ctx.rect(apple.x, apple.y, BASE_SNAKE_HEIGHT, BASE_SNAKE_HEIGHT); // Apple size remains the same
            ctx.fillStyle = "#FF0000";
            ctx.fill();
            ctx.closePath();
        };

        const moveSnake = () => {
            if (direction) {
                setSnake((prevSnake) => {
                    const newSnake = [...prevSnake];
                    const snakeHead = { x: newSnake[0].x, y: newSnake[0].y };

                    for (let i = newSnake.length - 1; i > 0; i--) {
                        newSnake[i].x = newSnake[i - 1].x;
                        newSnake[i].y = newSnake[i - 1].y;
                    }

                    switch (direction) {
                        case "right":
                            snakeHead.x += SNAKE_SPEED;
                            break;
                        case "left":
                            snakeHead.x -= SNAKE_SPEED;
                            break;
                        case "up":
                            snakeHead.y -= SNAKE_SPEED;
                            break;
                        case "down":
                            snakeHead.y += SNAKE_SPEED;
                            break;
                        default:
                            break;
                    }

                    newSnake[0] = snakeHead;

                    handleAppleCollision(newSnake);
                    handleWallCollision(snakeHead);
                    handleBodyCollision(newSnake);

                    return newSnake;
                });
            }
        };

        const handleWallCollision = (snakeHead) => {
            if (snakeHead.x >= canvas.width || snakeHead.x < 0) {
                onGameOver("wall");
            }
            if (snakeHead.y >= canvas.height || snakeHead.y < 0) {
                onGameOver("wall");
            }
        };

        const handleBodyCollision = (newSnake) => {
            const snakeHead = newSnake[0];
            for (let i = 1; i < newSnake.length; i++) {
                if (snakeHead.x === newSnake[i].x && snakeHead.y === newSnake[i].y) {
                    onGameOver("self");
                }
            }
        };

        const handleAppleCollision = (newSnake) => {
            const snakeHead = newSnake[0];
            const appleSize = BASE_SNAKE_HEIGHT; // Apple size remains consistent with snake height
            const { width, height } = getSnakeDimensions(direction);

            if (
                snakeHead.x < apple.x + appleSize &&
                snakeHead.x + width > apple.x &&
                snakeHead.y < apple.y + appleSize &&
                snakeHead.y + height > apple.y
            ) {
                setScore(score + 1);

                setApple({
                    x: Math.floor((Math.random() * canvas.width) / SNAKE_SPEED) * SNAKE_SPEED,
                    y: Math.floor((Math.random() * canvas.height) / SNAKE_SPEED) * SNAKE_SPEED,
                });

                newSnake.push({
                    x: newSnake[newSnake.length - 1].x,
                    y: newSnake[newSnake.length - 1].y,
                });
            }
        };

        const handleKeyPress = (e) => {
            switch (e.key) {
                case "ArrowRight":
                    setDirection("right");
                    break;
                case "ArrowLeft":
                    setDirection("left");
                    break;
                case "ArrowUp":
                    setDirection("up");
                    break;
                case "ArrowDown":
                    setDirection("down");
                    break;
                default:
                    break;
            }
        };

        const handleTouchStart = (e) => {
            const touchStartX = e.touches[0].clientX;
            const touchStartY = e.touches[0].clientY;

            const handleTouchMove = (e) => {
                const touchEndX = e.touches[0].clientX;
                const touchEndY = e.touches[0].clientY;

                const xDiff = touchEndX - touchStartX;
                const yDiff = touchEndY - touchStartY;

                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    setDirection(xDiff > 0 ? "right" : "left");
                } else {
                    setDirection(yDiff > 0 ? "down" : "up");
                }

                window.removeEventListener("touchmove", handleTouchMove);
            };

            window.addEventListener("touchmove", handleTouchMove);
        };

        window.addEventListener("keydown", handleKeyPress);
        window.addEventListener("touchstart", handleTouchStart);

        const interval = setInterval(() => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            drawSnake();
            drawApple();
            moveSnake();
        }, 150);

        return () => {
            clearInterval(interval);
            window.removeEventListener("keydown", handleKeyPress);
            window.removeEventListener("touchstart", handleTouchStart);
        };
    }, [snake, direction]);

    return (
        <div className="container">
            <canvas className="gameCanvas" ref={canvasRef} width={750} height={420} />
        </div>
    );
};

export default GamePieces;
