import './App.css'
import GameState from './components/GameState';

const App = () => {
  return (
    <div className='container'>
      <>
        <GameState />
      </>
    </div>
  );
};

export default App;